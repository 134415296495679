import { Link } from "gatsby"
import PropTypes from "prop-types"
import Img from "gatsby-image"
import React from "react"
import { Global, css } from "@emotion/core"
import styled from "@emotion/styled"
import { toEm, toRem, breakPoints, isEmpty } from "../styles/utils"

const ThumbImg = styled.div`
  background-color: var(--work-background-color);
  color: var(--white);
  grid-area: var(--grid-area);

  .project-desc-wrapper {
    transition: 0.3s;
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      .project-desc-wrapper {
        opacity: 1;
      }
    }
  }
`

const ProjectInfo = css`
  height: auto;
  color: var(--gray-dark);
  display: block;
  position: relative;
`

const ThumbPadded = styled.div`
  background-color: var(--work-background-color);
  overflow: hidden;

  a {
    ${ProjectInfo};
  }

  @media (min-width: ${breakPoints.small}) {
    margin-bottom: 0;
  }
`

const ProjectDescWrapper = styled.div`
  background-color: hsla(220, 23%, 10%, 0.8);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
`

const ProjectDesc = styled.div`
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
`

const Title = styled.h3`
  --title-color: var(--white);
  margin-bottom: 0;
  padding-left: ${toRem(25)};
  font-weight: 600;
`

const Description = styled.p`
  --body-font-size: ${toEm(14)};
  color: var(--gray-lightest);
  padding-left: ${toRem(25)};
  padding-bottom: ${toRem(25)};
  padding-right: ${toRem(60)};
  margin-bottom: 0;
  text-transform: capitalize;

  > span {
    display: none;
  }
`

const Thumb = ({ data, index }) => {
  const { tags, title, date, color, thumbImg } = data.frontmatter
  const workBgColor = isEmpty(color) ? `var(--gray-lightest)` : color

  return (
    <>
      <Global
        styles={css`
          ${`#ThumbImg-${index}`} {
            --work-background-color: ${workBgColor};
            --work-link-color: ${workBgColor};
            --grid-area: ${`ThumbImg-${index}`};
          }
        `}
      />
      <ThumbImg id={`ThumbImg-${index}`} className={`thumb-${index}`}>
        <ThumbPadded>
          <Link to={data.fields.slug}>
            <Img
              alt={`${title} - Work thumbnail`}
              backgroundColor={color}
              fluid={thumbImg.childImageSharp.fluid}
              placeholderClassName="gatsby-image-placeholder"
            />
            <ProjectDescWrapper className="project-desc-wrapper">
              <ProjectDesc>
                <Title>{title}</Title>
                <Description>
                  {tags.sort().join(", ")}
                  <span>{date}</span>
                </Description>
              </ProjectDesc>
            </ProjectDescWrapper>
          </Link>
        </ThumbPadded>
      </ThumbImg>
    </>
  )
}

Thumb.propTypes = {
  data: PropTypes.shape().isRequired,
  index: PropTypes.number.isRequired,
}

export default Thumb
