import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import { breakPoints, gutter, letterSpacing, toEm, toRem } from "../styles/utils"
import Container from "./container"

const HeroCopy = styled(Container)`
  align-items: center;
  min-height: 55vh;
  text-align: center;

  @media (min-width: ${breakPoints.medium}) {
    min-height: 38vh;
    padding-top: ${toRem(120)};
    padding-bottom: ${toRem(120)};
  }
`

const HeroWrapper = styled.div`
  ${gutter};

  @media (min-width: ${breakPoints.small}) {
    width: ${toRem(500)};
  }
  @media (min-width: ${breakPoints.medium}) {
    width: ${toRem(650)};
  }
  @media (min-width: ${breakPoints.large}) {
    width: ${toRem(740)};
  }
`

const HeroTitle = styled.h1`
  --title-font-size: ${toEm(32)};
  letter-spacing: ${letterSpacing(15)};
  line-height: 1;
  margin: 0;

  @media (min-width: ${breakPoints.small}) {
    --title-font-size: ${toEm(46)};
    letter-spacing: ${letterSpacing(15)};
  }
  @media (min-width: ${breakPoints.medium}) {
    --title-font-size: ${toEm(60)};
    letter-spacing: ${letterSpacing(15)};
    padding-top: 0;
    padding-bottom: 0;
  }
  @media (min-width: ${breakPoints.large}) {
    --title-font-size: ${toEm(74)};
    letter-spacing: ${letterSpacing(15)};
  }
`

const HeroDesc = styled.p`
  margin-bottom: 0;
  margin-top: ${toRem(15)};

  @media (min-width: ${breakPoints.medium}) {
    --body-font-size: ${toEm(24)};
    margin-top: ${toRem(25)};
    padding-right: ${toRem(30)};
    padding-left: ${toRem(30)};
  }
`

const SeparatorTitle = styled.h2`
  --title-color: var(--gray-darker);
  ${gutter};
  padding-bottom: ${toRem(30)};
  padding-top: ${toRem(30)};
  width: 100%;
  margin: 0;

  @media (min-width: ${breakPoints.medium}) {
    padding-bottom: ${toRem(60)};
  }
`

const Hero = () => (
  <>
    <HeroCopy>
      <HeroWrapper>
        <HeroTitle>A pixel pusher in the design process.</HeroTitle>
        <HeroDesc>
          <Link to="/about/">Oscar</Link> is a designer with immense interest in crafting the user
          interface and occasionally an illustrator. Previously led the design at{" "}
          <OutboundLink href="https://sci.ph">SCI</OutboundLink>, a fintech company.
        </HeroDesc>
      </HeroWrapper>
    </HeroCopy>
    <Container id="recent">
      <SeparatorTitle>Recent Work</SeparatorTitle>
    </Container>
  </>
)

export default Hero
